<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      :isOperateColumn="false"
    >
      <template #headBtnSlot>
        <v-button text="返回" @click="back"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          label="姓名"
          v-model="name"
          placeholder="请输入姓名"
          disabled
        ></v-input>
        <v-date-picker
          label="选择时间"
          v-model="time"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="创建开始时间"
          endPlaceholder="创建结束时间"
        />
        <v-select
          label="订单状态"
          clearable
          placeholder="请选择订单状态"
          :options="orderStatusOps"
          v-model="searchParam.orderStatus"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { getFoodOrderByOldIdUrl } from "./api.js";
import { orderStatusMap, orderStatusOps, eatWayMap, eatWayOps } from "./map.js";
import { Message, MessageBox } from "element-ui";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
export default {
  name: "healthyMindMeals",
  data() {
    return {
      orderStatusOps: orderStatusOps(),
      name: "",
      tableUrl: getFoodOrderByOldIdUrl,
      searchParam: {
        orderStatus: null,
        beginTime: "",
        endTime: "",
        id: null,
      },
      headers: [
        {
          prop: "id",
          label: "订单号",
        },
        {
          prop: "insertTime",
          label: "下单时间",
        },
        {
          prop: "orderStatus",
          label: "订单状态",
          formatter: (row, prop) => {
            return orderStatusMap[row[prop]] || "--";
          },
        },
        {
          prop: "orderType",
          label: "就餐方式",
          formatter: (row, prop) => {
            return eatWayMap[row[prop]] || "--";
          },
        },
        {
          prop: "foodNames",
          label: "菜品",
        },
        {
          prop: "countPrice",
          label: "金额（元）",
        },
      ],
    };
  },
  created() {
    let { personName, id } = this.$route.query;
    this.name = personName;
    this.searchParam.id = id;
    this.$setBreadList("用餐情况");
  },
  computed: {
    time: {
      get() {
        return !this.searchParam.beginTime && !this.searchParam.endTime
          ? []
          : [this.searchParam.beginTime, this.searchParam.endTime];
      },
      set(val) {
        [this.searchParam.beginTime, this.searchParam.endTime] = val || [
          "",
          "",
        ];
      },
    },
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="less">
.page {
  box-sizing: border-box;
  height: 100%;
}
</style>
