import { mapHelper } from "@/utils/common";
const orderStatus = [
  {
    value: 99,
    label: "已取消",
  },
 
];
const { map: orderStatusMap, setOps: orderStatusOps } =
  mapHelper.setMap(orderStatus);
const eatWay = [
  {
    value: 1,
    label: "堂食",
  },
  {
    value: 2,
    label: "外送",
  },
];
const { map: eatWayMap, setOps: eatWayOps } = mapHelper.setMap(eatWay);
//菜品类别
const foodTime = [
  {
    value: 1,
    label: "午餐",
  },
  {
    value: 2,
    label: "晚餐",
  },
];
const { map: foodTimeMap, setOps: foodTimeOps } = mapHelper.setMap(foodTime);
export {
  orderStatusMap,
  orderStatusOps,
  eatWayMap,
  eatWayOps,
  foodTimeOps,
  foodTimeMap,
};
