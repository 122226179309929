const addOrUpdateInfoUrl = `/gateway/hc-health/manage/healthHeart/addOrUpdateInfo`; //新增/编辑老年人信息
const getOldPersonListUrl = `/gateway/hc-health/manage/healthHeart/getOldPersonList`; //查询老年人信息列表
const getOldPersonDetailsUrl = `/gateway/hc-health/manage/healthHeart/getOldPersonDetails`; //查询老年人信息详情
const deleteInfoUrl = `/gateway/hc-health/manage/healthHeart/deleteInfo`; //删除老年人信息
const deleteFoodInfoUrl = `/gateway/hc-health/manage/healthHeart/deleteFoodInfo`; //删除菜品信息
const getFoodListUrl = `/gateway/hc-health/manage/healthHeart/getFoodList`; //获取菜品列表
const addOrUpdateFoodInfoUrl = `/gateway/hc-health/manage/healthHeart/addOrUpdateFoodInfo`; //新增/编辑菜品
const getFoodTypeUrl = `/gateway/hc-health/manage/healthHeart/getFoodType`; //获取菜品类别
const getFoodOrderByOldIdUrl = `/gateway/hc-health/manage/healthHeart/getFoodOrderByOldId`; //老人用餐详情列表
//老年人审核
const updateInfo = `/gateway/hc-health/manage/healthHeart/audit`;
//老年人身份郑编辑
const oldPersonEdit = `/gateway/hc-health/manage/healthHeart/oldPersonEdit`;
//食堂订单列表
const getFoodOrderByOldId = `/gateway/hc-health/manage/healthHeart/getFoodOrderByOldId`;
//食堂订单列表
const exportFoodOrder = `/gateway/hc-health/manage/healthHeart/exportFoodOrder`;
export {
  addOrUpdateInfoUrl,
  getOldPersonListUrl,
  getOldPersonDetailsUrl,
  deleteInfoUrl,
  deleteFoodInfoUrl,
  getFoodListUrl,
  addOrUpdateFoodInfoUrl,
  getFoodTypeUrl,
  getFoodOrderByOldIdUrl,
  updateInfo,
  oldPersonEdit,
  getFoodOrderByOldId,
  exportFoodOrder,
};
